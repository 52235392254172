import { Button, Modal } from "antd"
import { CookiePopUp, Footer, Header, Loading } from "./components"
import {
  Gasthof,
  Home,
  Jobs,
  Kontakt,
  Spezialitaeten,
  Zimmer,
} from "./containers"
import React, { Component, Suspense, lazy } from "react"
import { Route, Router, Switch } from "react-router-dom"
import { inject, observer } from "mobx-react"

import { checkCookieConsent } from "./utils/Cookies"
import history from "./utils/History"
import styles from "./App.module.scss"

const Gallery = lazy(() => import("./containers/Gallery/Gallery"))
const CookiePolicy = lazy(() =>
  import("./containers/CookiePolicy/CookiePolicy")
)
const PrivacyPolicy = lazy(() =>
  import("./containers/PrivacyPolicy/PrivacyPolicy")
)
const Impressum = lazy(() => import("./containers/Impressum/Impressum"))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { holidaysOpen: true }
    this.renderCookiePopUp = this.renderCookiePopUp.bind(this)
  }

  // Check for Cookie Consent on mount

  componentDidMount() {
    checkCookieConsent()
  }

  // Cookies accepted? --> If no, render Cookie Popup

  renderCookiePopUp() {
    if (this.props.store.cookies.enabled === true) {
      console.log("User accepted Cookies --> All Cookies enabled")
    } else if (this.props.store.cookies.enabled === false) {
      console.log("User denied Cookies --> Only necessary Cookies enabled")
    } else {
      return <CookiePopUp />
    }
  }

  lazyload(Component) {
    return (props) => (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    )
  }

  handleOk = () => {
    this.setState({ holidaysOpen: false })
  }

  handleCancel = () => {
    this.setState({ holidaysOpen: false })
  }

  // Render Function

  render() {
    return (
      <Router history={history}>
        <div>
          {this.renderCookiePopUp()}

          <Modal
            visible={this.state.holidaysOpen}
            title={<strong>Kleine Pause</strong>}
            maskClosable
            closable
            className={styles.popup}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="submit" type="primary" onClick={this.handleOk}>
                Okay
              </Button>,
            ]}
          >
            Liebe Gäste,<br />
            unser Gasthof macht vom 03.03.2025 bis 11.03.2025 eine kleine Pause.
            <br /><br />
            Ab dem 12.03. freuen wir uns schon wieder darauf Sie bei uns begrüßen zu dürfen. 
            <br /><br />
            Wussten Sie übrigens schon, dass wir auch auf Instagram zu finden sind?
            Schauen Sie doch einmal vorbei: <a href="https://www.instagram.com/landgasthofsonnehaldenwang" target="_blank">Zu Instagram!</a>
            <br /><br />
            viele Grüße,<br />
            Ihre Familie Kovacevic & Team
          </Modal>

          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/gasthof" component={Gasthof} />
            <Route exact path="/zimmer" component={Zimmer} />
            <Route exact path="/spezialitaeten" component={Spezialitaeten} />
            <Route exact path="/kontakt" component={Kontakt} />
            <Route
              exact
              path="/bildergalerie"
              component={this.lazyload(Gallery)}
            />
            <Route exact path="/jobs" component={Jobs} />

            {/* Legal Routes */}
            <Route path="/cookies" component={this.lazyload(CookiePolicy)} />
            <Route
              path="/datenschutz"
              component={this.lazyload(PrivacyPolicy)}
            />
            <Route path="/impressum" component={this.lazyload(Impressum)} />

            <Route component={Home} />
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default inject("store")(observer(App))
